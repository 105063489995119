<template>
  <app-timeline>
    <div
      v-if="historiqueStatus.length <= 0"
      class="text-center"
    >
      {{ $t('table_columns.empty_text') }}
    </div>
    <app-timeline-item
      v-for="(timeline_item, index) of historiqueStatus"
      :key="index"
      :variant="getTreatmentTimelineColor(timeline_item.name)"
      :fill-border="true"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <div>
          <h6>
            <b-badge
              v-if="timeline_item.name === 'comment'"
              variant="secondary"
            >
              {{ $t('complaints.complaint_details.treatment_timeline.status.comment') }}
            </b-badge>
            <b-badge
              v-if="timeline_item.name === 'not_treated'"
              variant="warning"
            >
              {{ $t('complaints.complaint_details.treatment_timeline.status.not_treated') }}
            </b-badge>
            <b-badge
              v-if="timeline_item.name === 'in_treatment'"
              variant="success"
            >
              {{ $t('complaints.complaint_details.treatment_timeline.status.in_treatment') }}
            </b-badge>
          </h6>
        </div>
        <div>
          <small class="mr-25">{{ $t('complaints.complaint_details.treatment_timeline.by') }}</small>
          <small class="text-primary">{{ timeline_item.user && timeline_item.user.fullname }}</small>
          <small class="mr-25">, {{ $t('complaints.complaint_details.treatment_timeline.on') }}</small>
          <small class="text-primary">{{ timeline_item.created_at }}</small>
        </div>
      </div>
      <div><small>{{ timeline_item.reason }}</small></div>
    </app-timeline-item>

  </app-timeline>
</template>

<script>
import {
  VBToggle,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import utilsService from '@/services/utils/utils.service'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BBadge,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    statusData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      getTreatmentTimelineColor: utilsService.getTreatmentTimelineColor,
    }
  },
  data() {
    return {
      historiqueStatus: [],
    }
  },

  watch: {
    statusData: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        if (val !== null) {
          const { statuses } = this.statusData
          this.historiqueStatus = statuses || []
        }
      },
    },
  },
}
</script>
